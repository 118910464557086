import * as actionTypes from '../store/actions';
import Cookies from 'js-cookie'
import callApi from '../helpers/conectorApi';

export const loginBackend = (infoLogin) => {
    return async (dispatch) => {
        if (infoLogin === undefined) {
            let token = Cookies.get("auth");
            if (token) {
                token = atob(token);
                const { logged, data } = await InformacionUsuario();
                if (logged === true) {
                    if (data.changePass === 1) {
                        data.changePass = true;
                    } else {
                        data.changePass = false;
                    }
                    data.token = token;

                    dispatch(login(token, data, "", logged));
                } else {
                    dispatch(login("", "", data, false));
                }
            }
        } else {
            let dataResult = await callApi('/auth/login', {
                method: 'POST',
                body: JSON.stringify(infoLogin)
            },true);
            if (dataResult) {
                const { ok, data: loggeData } = dataResult;
                if (ok === true) {
                    let { token } = loggeData;
                    Cookies.set("auth", btoa(token));
                    const { logged, data } = await InformacionUsuario();
                    if (logged === true) {
                        if (data.changePass === 1) {
                            data.changePass = true;
                        } else {
                            data.changePass = false;
                        }
                        data.token = token;
                        dispatch(login(token, data, "", logged));
                    } else {
                        dispatch(login("", "", data, false));
                    }
                } else {
                    dispatch(login("", "", loggeData, false));
                }
            } else {
                const { data } = dataResult;
                dispatch(login("", "", data, false));
            }
        }

    }
}

const login = (token, userInfo, error = "", logged = false) => ({
    type: actionTypes.LOGIN,
    payload: {
        token,
        userInfo,
        logged: logged,
        errorMessage: error
    }
});

const InformacionUsuario = async () => {
    let response = await callApi('/usuario/info',{},true);
    if (response) {
        const { ok, data } = response;
        if (ok === true) {
            return {
                logged: true,
                data
            }
        } else {
            return {
                logged: false
            };
        }
    } else {
        return {
            logged: false
        };
    }
}

export const logout = () => {
    Cookies.remove("auth");
    return {
        type: actionTypes.LOGOUT
    };
}

export const resetErrorMessage = () => ({
    type: actionTypes.RESET_ERROR_MESSAGE
});
