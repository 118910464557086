export default {
  defaultPath: "/socio/home",
  basename: ".", // only at build time to set, like ///able-pro/react/default
  layout: "vertical", // vertical, horizontal
  subLayout: "", // horizontal-2
  collapseMenu: false, // mini-menu
  layoutType: "menu-light", // menu-dark, menu-light, dark
  headerBackColor: "background-gray", // background-blue, background-red, background-purple, background-info, background-green, background-dark, background-grd-blue, background-grd-red, background-grd-purple, background-grd-info, background-grd-green, background-grd-dark, background-img-1, background-img-2, background-img-3, background-img-4, background-img-5, background-img-6
  rtlLayout: true,
  navFixedLayout: true,
  headerFixedLayout: false,
  boxLayout: false,
  timeOut: 1000 * 60 * 15,
  urlApi: "https://miapiv2.portafoliodiversificado.com",
  //urlApi: "https://miapidev.portafoliodiversificado.com",
  //urlApi: "http://localhost:3050",
  modulo: "1",
  maxSizeCoincidencia: 10e6,
  maxSizeSmih: 10e6,
  maxSizeOst: 10e6,
  links: {
    linkrecomendar: "https://www.portafoliodiversificado.com/referidos",
    linkdropbox: "https://www.portafoliodiversificado.com/",
    linknotion: "https://www.portafoliodiversificado.com/",
    linkduda: "https://www.portafoliodiversificado.com/tickets",
    linkotrainversion: "https://www.portafoliodiversificado.com/videollamada",
    linkTickets: "https://www.portafoliodiversificado.com/tickets",
    linkFormulario: "https://forms.gle/8CRCmeb5wdoeRRVw5"
  },
  dataPortafolio: {
    tituloIndicador1: "Entrega de Resultados",
    tituloIndicador2: "Q2 2023",
    imagenIndicador: "https://mi.portafoliodiversificado.com/quarterImage.jpeg"
  },
  keyCryptoJS: "419DBA8227CCB8BFE1A6D5D7A72AD"
};
