import React, { useState, useContext } from "react";
import { Card, Form, Col, Row, Button } from "react-bootstrap";
import config from "../../../config";
import axios from "axios";

import {
  ValidationForm,
  TextInput
} from "react-bootstrap4-form-validation";
import userContext from "../../../context/userContext";

import "./cambioContrasena.css"
import { useDispatch, useSelector } from "react-redux";
import { NotFound } from "../NotFound";
import { logout } from "../../../actions/auth";
import Cookies from "js-cookie";
import callApi from "../../../helpers/conectorApi";
function CambioContrasena({
  usuario,
  onCambioConDone,
  onCerrarModalCambio,
  mensajeAlerta,
  history
}) {
  const dispatch = useDispatch();
  const { token, logged, userInfo: user = {} } = useSelector(state => state);
  const configReq = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [usuarioId] = useState(user?.usuarioId);
  const [currentPass, setcurrentPass] = useState("");

  const [newPass, setnewPass] = useState("");

  const [confNewPass, setconfNewPass] = useState("");

  const updatePass = async () => {
    try {
      const json = {
        usuarioId: usuarioId,
        passNew: newPass,
        confirm: confNewPass,
      };

      let response = await callApi('/auth/resetPass', {
        method: 'PUT',
        body: JSON.stringify(json)
      }, true);
      if (response.ok === true) {
        mensajeAlerta(
          "Cambio contrasena",
          response.data,
          "success"
        );
        onCambioConDone(true);
      } else {
        mensajeAlerta(
          "Cambio contrasena",
          `[${response.data}]`,
          "error"
        );
      }
    } catch (exc) {
      mensajeAlerta(
        "Cambio contrasena",
        `[${exc.error}]`,
        "error"
      );
    }
  }

  //EVENT HANDLERS
  const handleCloseCambioConUsuario = (e) => {
    Cookies.remove("auth");
    dispatch(logout());
    history.replace("/auth/login");
  };
  const handleCambioCon = (e, formData, inputs) => {
    e.preventDefault();

    updatePass();


  };

  const handleErrorSubmit = (e, formData, errorInputs) => {
    e.preventDefault();
  };

  const matchPassword = (value) => {

    return value && value === newPass;
  };
  return (

    <div className="pass-wrapper d-flex justify-content-center">
      {logged === true ?
        <div className="pass-form">
          <Card className="cardContrasena">
            <Card.Body>
              <h2 className="pb-4">Cambia la contraseña</h2>
              <ValidationForm onSubmit={handleCambioCon}
                onErrorSubmit={handleErrorSubmit}
                setFocusOnError
                defaultErrorMessage={{ required: "El campo es requerido.", minLength: "Ingresar por lo menos {minLength} caracteres" }}
              >
                <Row>
                  <Col xs="12">

                    <Form.Group className="pb-3  font-weight-bolder">
                      <Form.Label>Contraseña Nueva</Form.Label>
                      <TextInput
                        type="password"
                        className="form-control form-controlPortafolio"
                        id="newPass"
                        required
                        name="newPass"
                        placeholder="Contraseña nueva"
                        value={newPass}
                        onChange={(e) => setnewPass(e.target.value)}
                      />
                    </Form.Group>

                    <Form.Group className="pb-4 font-weight-bolder">
                      <Form.Label>Confirma la nueva contraseña</Form.Label>
                      <TextInput
                        type="password"
                        id="confPass"
                        className="form-control form-controlPortafolio  font-weight-bolder"
                        name="confPass"
                        placeholder="Confirma la nueva contraseña"
                        value={confNewPass}
                        required
                        onChange={(e) => setconfNewPass(e.target.value)}

                        validator={matchPassword}
                        errorMessage={{ required: "Es necesario que confirme la contrasena", validator: "Contrasenas no coinciden" }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className=" d-flex justify-content-center">
                    <Button
                      id="btnCambiarCon"
                      className="form-buttonPortafolioDark font-weight-bolder"
                      name="btnCambiarCon"
                      key="btnCambiarCon"
                      type="submit"

                      size="md"
                    >

                      Guardar
                    </Button>

                    <Button
                      id="btnCloseCon"
                      name="btnCloseCon"
                      className="form-buttonPortafolio font-weight-bolder"
                      key="btnCloseCon"
                      variant="outline-danger"
                      onClick={handleCloseCambioConUsuario}
                      size="md"
                    >

                      Cancelar
                    </Button>
                  </Col>
                </Row>
              </ValidationForm>
            </Card.Body>
          </Card>
        </div>
        : <NotFound />
      }
    </div>
  );
}

export default CambioContrasena;
