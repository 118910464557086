import React, { useState } from 'react';

import './Loader.css';

export default function Loader({ variant = 'dark' }) {
  const [cssLoader, setcssLoader] = useState(variant === "dark" ? "lds-dark" : variant === "light" ? "lds-light" : "lds-dark");

  return (
    <div className={`lds-grid ${cssLoader}`}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}
