import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import "../FormularioPago.css";
import userContext from "../../../../context/userContext";

import { Card, Form, Col, Row, Button } from "react-bootstrap";

import MensajeAlertaSwal from "../../MensajeAlerta/MensajeAlertaSwal";
import {
  ValidationForm,
  TextInput,
  SelectGroup,
  Checkbox,
  FileInput
} from "react-bootstrap4-form-validation";
import unitedstates from "../../../../assets/images/portafolio/icons/united-states-logo.png";
import globo from "../../../../assets/images/portafolio/icons/internet-logo.png";
import payoneer from "../../../../assets/images/portafolio/icons/Payoneer-logo.png";
import "./Pago_ach_usa_pay.css";
import countries_list from "../../../../utils/us_states.json";
export default function Pago_ach_usa_pay(props) {
  const [datos, setDatos] = useState({
    nombrelegal: props.datos.nombrelegal,
    email: props.datos.email,
    routing: props.datos.routing,
    numerocuenta: props.datos.numerocuenta,
    nombreBanco: props.datos.nombreBanco,
    tipoCuenta: props.datos.tipoCuenta,
    pais: props.datos.pais,
    direccion1: props.datos.direccion1,
    direccion2: props.datos.direccion2,
    ciudad: props.datos.ciudad,
    estado: props.datos.estado,
    codigopostal: props.datos.codigopostal,
    confirmacion: props.datos.confirmacion
  });
  const [comboPaises, setcomboPaises] = useState();
  const [comboEstados, setcomboestados] = useState([]);
  const [estadoSel, setestadoSel] = useState(props.datos.estado);
  const [paisSel, setpaisSel] = useState(props.datos.pais);
  const [file, setfile] = useState();
  const [archivoName, setarchivoName] = useState(
    props.datos.file_form_name
      ? props.datos.file_form_name
      : "Seleccionar archivo"
  );
  const onFileHandler = (event) => {
    if (event.target.files.length > 0) {
      setfile(event.target.files[0]);
      setarchivoName(event.target.files[0].name);
      handleInputChange({
        target: {
          name: "file_form_name",
          value: event.target.files[0].name
        }
      });
    } else {
      setfile();
      setarchivoName("Seleccionar Archivo");
    }
  };
  useEffect(() => {
    let countries = [];
    countries = countries_list.countries.map((item) => {
      return {
        key: item.country,
        value: item.country
      };
    });
    setcomboPaises(countries);

    if (datos.pais) {
      let estados = countries_list.countries.filter((item) => {
        return item.country === datos.pais;
      });
      if (estados.length > 0) {
        if (estados[0].states.length > 0) {
          let cestados = estados[0].states.map((item) => {
            return {
              key: item,
              value: item
            };
          });
          setcomboestados(cestados);
        }
      }
    }

    return () => {};
  }, []);

  function updateEstados(e) {
    setestadoSel(null);
    let estados = countries_list.countries.filter((item) => {
      return item.country === e.target.value;
    });
    if (estados.length > 0) {
      if (estados[0].states.length > 0) {
        let cestados = estados[0].states.map((item) => {
          return {
            key: item,
            value: item
          };
        });
        setcomboestados(cestados);
      }
    }
  }

  const handleInputChange = (
    event,
    campoEspecial = false,
    tipoCampo = null,
    campoName = null,
    deptoSufijo = null,
    deptoprefijo = ""
  ) => {
    if (campoEspecial === false) {
      setDatos({
        ...datos,
        [event.target.name]: event.target.value
      });
    } else {
      switch (tipoCampo) {
        case "depto":
          let campo1 = `${deptoprefijo}depto${deptoSufijo}`;
          let campo2 = `${deptoprefijo}muni${deptoSufijo}`;
          setDatos({
            ...datos,
            [campo1]: event.target.value,
            [campo2]: ""
          });
          break;

        case "multi":
          setDatos({
            ...datos,
            [campoName]: event
          });
          break;
        case "check":
          setDatos({
            ...datos,
            [campoName]: event.target.checked
          });
          break;
        default:
        // code block
      }
    }
  };
  return (
    <Card>
      <Card.Body>
        <Row className="HeaderOPC1">
          <div className="HeaderOPC1_row1">
            <img src={unitedstates} className="card-img-opc" alt="ACH"></img> /
            <img src={payoneer} className="card-img-opc" alt="ACH"></img>
          </div>
          <div className="HeaderOPC1_row2">ACH Estados Unidos / Payoneer</div>
          <div className="HeaderOPC1_row3">
            Hasta 3 días laborales para hacer efectiva la transferencia
          </div>
        </Row>
        <Row>
          <Col xs="12">
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Nombre exacto legal de la persona o empresa*
              </Form.Label>

              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="nombrelegal"
                id="nombrelegal"
                placeholder="Ingresa el nombre legal de persona o empresa"
                required
                type="text"
                autoComplete="off"
                value={datos.nombrelegal ? datos.nombrelegal : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Correo electrónico
              </Form.Label>

              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="email"
                id="email"
                placeholder="Ingresa Correo electrónico"
                required
                type="email"
                autoComplete="off"
                value={datos.email ? datos.email : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Routing #
              </Form.Label>

              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="routing"
                id="routing"
                placeholder="Ingrese Routing"
                required
                type="text"
                autoComplete="off"
                value={datos.routing ? datos.routing : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Número de cuenta
              </Form.Label>

              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="numerocuenta"
                id="numerocuenta"
                placeholder="Ingresa Número de cuenta"
                required
                type="text"
                autoComplete="off"
                value={datos.numerocuenta ? datos.numerocuenta : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Nombre del Banco
              </Form.Label>

              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="nombreBanco"
                id="nombreBanco"
                placeholder="Ingresa Nombre de banco"
                required
                type="text"
                autoComplete="off"
                value={datos.nombreBanco ? datos.nombreBanco : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Tipo de Cuenta
              </Form.Label>

              <SelectGroup
                className="form-control form-controlPortafolioFormulario"
                name="tipoCuenta"
                id="tipoCuenta"
                defaultValue={datos.tipoCuenta}
                value={datos.tipoCuenta}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                required
              >
                <option key="" value="">
                  ---Seleccione una opcion---
                </option>{" "}
                <option key="Business Checking" value="Business Checking">
                  Business Checking
                </option>
                <option key="Business Savings" value="Business Checking">
                  Business Savings
                </option>
                <option key="Personal Checking" value="Personal Checking">
                  Personal Checking
                </option>
                <option key="Personal Savings" value="Personal Savings">
                  Personal Savings
                </option>
              </SelectGroup>
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="font-size-subtitle">
                Dirección Legal
              </Form.Label>
              <hr></hr>
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                País o territorio
              </Form.Label>

              <SelectGroup
                className="form-control form-controlPortafolioFormulario"
                name="pais"
                id="pais"
                value={paisSel}
                required
                onChange={(e) => {
                  /* handleInputChange(e); */
                  setpaisSel(e.target.value);
                  updateEstados(e);
                }}
              >
                <option key="" value="">
                  ---Seleccione una opcion---
                </option>{" "}
                {!(comboPaises === undefined)
                  ? comboPaises.map((fbb) => (
                      <option key={fbb.key} value={fbb.value}>
                        {fbb.value}
                      </option>
                    ))
                  : null}
              </SelectGroup>
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Address Line 1
              </Form.Label>
              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="direccion1"
                id="direccion1"
                placeholder="Address Line 1"
                required
                type="text"
                autoComplete="off"
                value={datos.direccion1 ? datos.direccion1 : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Address Line 2
              </Form.Label>
              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="direccion2"
                id="direccion2"
                placeholder="Address Line 2"
                required
                type="text"
                autoComplete="off"
                value={datos.direccion2 ? datos.direccion2 : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Ciudad
              </Form.Label>
              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="ciudad"
                id="ciudad"
                placeholder="Ciudad"
                required
                type="text"
                autoComplete="off"
                value={datos.ciudad ? datos.ciudad : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Estado
              </Form.Label>

              <SelectGroup
                className="form-control form-controlPortafolioFormulario"
                name="estado"
                id="estado"
                value={estadoSel}
                defaultValue={estadoSel}
                onChange={(e) => {
                  setestadoSel(e.target.value);
                }}
                required
              >
                <option key="" value="">
                  ---Seleccione una opcion---
                </option>{" "}
                {!(comboEstados === undefined)
                  ? comboEstados.map((fbb) => (
                      <option key={fbb.value} value={fbb.value}>
                        {fbb.value}
                      </option>
                    ))
                  : null}
              </SelectGroup>
            </Form.Group>

            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Código postal
              </Form.Label>
              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="codigopostal"
                id="codigopostal"
                placeholder="Código postal"
                required
                type="text"
                autoComplete="off"
                value={datos.codigopostal ? datos.codigopostal : ""}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Documento (foto de cheque o documento formal) de tu banco en
                donde se lea tu número de cuenta y nombre de banco de manera
                legible
              </Form.Label>
            </Form.Group>
            <Form.Group className="">
              <Form.Label
                className="form-control form-controlPortafolioFormulario form-controlPortafolioInputCustom"
                htmlFor="fileForm"
              >
                <span className="feather icon-upload-cloud icon-feather-portafolio"></span>
                <span>{archivoName}</span>
              </Form.Label>
              <FileInput
                required
                aria-describedby="custom-addons5"
                type="file"
                accept="application/pdf,image/jpg,image/jpeg,image/png,application/msword"
                className="inputfile-custom "
                id="fileForm"
                onChange={onFileHandler}
              />
            </Form.Group>
            <Form.Group className="form-group-checkbox-pd">
              <Checkbox
                name="confirmacion"
                label="Confirmo que mi información bancaria está correcta. Esto es solo una actualización de datos."
                id="confirmacion"
                required
                value={datos.confirmacion}
                onChange={(e) => {
                  handleInputChange(e, true, "check", "confirmacion");
                }}
                errorMessage="Es requerido confirmar la información es correcta."
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-left">
            <Button
              id="btnBack"
              name="btnBack"
              className="form-buttonPortafolio font-weight-bolder text-dark ml-0"
              key="btnBack"
              onClick={(e) => {
                props.handleBackClick();
                props.updateDatos(datos);
              }}
              variant="outline-success"
              size="md"
            >
              Atrás
            </Button>
            <Button
              id="btnSiguiente"
              className="form-buttonPortafolioDark font-weight-bolder ml-0"
              name="btnSiguiente"
              key="btnSiguiente"
              type="submit"
              onClick={(e) => {
                /*                 props.handleNextClick();
                 */
                let tags = {
                  estado: estadoSel,
                  pais: paisSel
                };
                let result = {
                  ...datos,
                  ...tags
                };
                if (!(file === undefined || file === null)) {
                  const data = new FormData();
                  var re = /(?:\.([^.]+))?$/;
                  data.append("File", file, file.name);

                  let filej = {
                    file_data: data
                  };
                  result = {
                    ...result,
                    ...filej
                  };
                }
                props.updateDatos(result);
              }}
              size="md"
            >
              Siguiente
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
