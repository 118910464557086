import React, { useState } from "react";
import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation'
import Aux from '../../hoc/_Aux'
import authLogo from "../../assets/images/logoLight.webp";
import MiniLoader from '../../App/components/Loader/MiniLoader';
import { Alert } from 'react-bootstrap';
import "./../../assets/scss/style.scss";
import "./resetpass.css";
import { NavLink } from "react-router-dom";
import config from "../../config";
import { useEffect } from "react";
const ResetPass = ({history}) => {
  const [user_name, setUser_name] = useState('');
  const [loading, setloading] = useState(false);
  const [showError, setshowError] = useState("");
  const [varianteMensaje, setvarianteMensaje] = useState("");

  const handleSubmitLogin = async (e) => {
    setshowError("");
    setloading(true)
    e.preventDefault();
    if (!(user_name === undefined && user_name === undefined)) {
      fetch(`${config.urlApi}/resetpass`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          user_name: String(user_name).trim().toLowerCase(),
        }),
      })
        .then((response) =>
          Promise.all([response.ok, response.json(), response.status])
        )
        .then(([responseOk, body, status]) => {
          if (responseOk) {
            let { code, data } = body.data;
            setUser_name('');
            if (code === 1) {
              setvarianteMensaje("success");
              setshowError(data);
            } else {
              setvarianteMensaje("danger");
              setshowError(data);
            }
            setloading(false)
          } else {
            setloading(false)
            setshowError(body.data);
          }
        })
        .catch((err) => {
          setloading(false)
          setvarianteMensaje("danger");
          setshowError(
            "Error con la comunicacion, comuniquese con el administrador."
          );
        });

    };
  };

  const handleErrorSubmit = (e, formData, errorInputs) => {
    setvarianteMensaje("");
    setshowError("");
  };

  const handleCancelar = () => {
    history.replace("/auth/login");
  }

  return (
    <Aux>
      <ValidationForm
        onSubmit={handleSubmitLogin}
        onErrorSubmit={handleErrorSubmit}
        setFocusOnError
        defaultErrorMessage={{ required: "El campo es requerido.", minLength: "Ingresar por lo menos {minLength} caracteres" }}
      >
        <div className="auth-wrapper">

          <div className="auth-content">
            <div className="card cardLogin">
              <div className="row align-items-center text-center">
                <div className="col-md-12">
                  <div className="card-body">
                    <img src={authLogo} alt="" className="img-fluid mb-4 imgLogoLogin" style={{ width: "70%" }} ></img>
                    <h4 className="mb-3 f-w-400">Restablecer Contraseña</h4>

                    <div>
                      <div className="input-group mb-3">
                        <TextInput
                          className="form-control form-controlLogin rounded-pill"
                          name="user_name"
                          id="user_name"
                          placeholder="Nombre de Usuario"
                          required
                          type="text"
                          autoComplete="off"
                          value={(user_name) ? user_name : ""}
                          onChange={(e) => setUser_name(e.target.value)}
                        />

                      </div>
                    </div>
                    <div className="form-group text-left mt-2 "></div>
                    {loading ? <MiniLoader></MiniLoader> :
                      <>
                        <button className="btn btn-block btn-success m-0 btn-login text-dark rounded-pill">
                          Restablecer
                        </button>
                        <div className="form-group text-left mt-2 "></div>
                        <button className="btn btn-block btn-primary btn-cancelarResetPass" type="button" onClick={handleCancelar}>
                        Cancelar
                        </button>
                      </>
                    }
                    {showError.length > 0 ? (
                      <Alert variant={varianteMensaje}>{showError}</Alert>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ValidationForm>
    </Aux >

  )
}
export default ResetPass;
