import React, { useEffect, useState } from "react";
import { Alert } from "react-bootstrap";
import { NavLink, Redirect } from "react-router-dom";
import "./../../assets/scss/style.scss";
import Aux from "../../hoc/_Aux";
import MiniLoader from "../../App/components/Loader/MiniLoader";
import "../../App/components/Token/TokenField.css";
import "./login.css";
import authLogo from "../../assets/images/logoLight.webp";
import { useDispatch, useSelector } from "react-redux";
import { ValidationForm, TextInput } from "react-bootstrap4-form-validation";
import { loginBackend, resetErrorMessage } from "../../actions/auth";
import config from "../../config";
import { useForm } from "../../hooks/useForm";
const Login = ({ history, location }) => {
  const dispatch = useDispatch();
  const { logged, errorMessage, userInfo } = useSelector((state) => state);
  const [loading, setloading] = useState(false);
  const [showError, setshowError] = useState("");
  const [varianteMensaje, setvarianteMensaje] = useState("");

  const [values, , , setValues] = useForm({
    usuario: "",
    password: ""
  });

  const handleUserName = ({ target: { value } }) => {
    let auxUsername = String(value).trim().toUpperCase();
    setValues({ ...values, usuario: auxUsername });
  };

  const hanldeOnChangePassword = ({ target: { value } }) => {
    setValues({ ...values, password: value });
  };

  const handleSubmitLogin = async (e) => {
    setshowError("");
    setloading(true);
    e.preventDefault();
    dispatch(loginBackend(values));
    dispatch(resetErrorMessage());
  };

  const validarSesion = () => {
    dispatch(loginBackend());
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setloading(false);
    setvarianteMensaje("danger");
    setshowError(errorMessage);
  }, [errorMessage]);

  const validarLogin = () => {
    if (logged === true) {
      history.replace(config.defaultPath);
    }
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    validarLogin();
  }, [logged]);

  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function () {
    window.history.pushState(null, "", window.location.href);
  };
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    validarSesion();
  }, []);

  return (
    <Aux>
      <ValidationForm
        onSubmit={handleSubmitLogin}
        setFocusOnError
        defaultErrorMessage={{
          required: "El campo es requerido.",
          minLength: "Ingresar por lo menos {minLength} caracteres"
        }}
      >
        <div className="auth-wrapper">
          <div className="auth-content">
            <div className="card cardLogin">
              <div className="row align-items-center text-center">
                <div className="col-md-12">
                  <div className="card-body">
                    <img
                      src={authLogo}
                      alt=""
                      className="img-fluid mb-4 imgLogoLogin"
                      style={{ width: "70%" }}
                    ></img>
                    <h4 className="mb-3 f-w-400">Inicia sesión</h4>

                    <div>
                      <div className="input-group mb-3">
                        <TextInput
                          className="form-control form-controlLogin rounded-pill"
                          name="usuario"
                          id="usuario"
                          placeholder="Usuario"
                          required
                          type="text"
                          autoComplete="off"
                          value={values.usuario}
                          onChange={handleUserName}
                        />
                      </div>

                      <div className="input-group mb-4">
                        <TextInput
                          className="form-control form-controlLogin rounded-pill"
                          name="password"
                          id="password"
                          placeholder="Contraseña"
                          required
                          type="password"
                          autoComplete="off"
                          value={values.password}
                          onChange={hanldeOnChangePassword}
                        />
                      </div>
                    </div>
                    <div className="form-group text-left mt-2 "></div>
                    {loading ? (
                      <MiniLoader></MiniLoader>
                    ) : (
                      <>
                        <button className="btn btn-block btn-success m-0 btn-login text-dark rounded-pill">
                          Ingresar
                        </button>
                        <div className="text-center">
                          <div className="link-portafolio">
                            ¿Olvidaste tu contraseña?
                            <NavLink to="/auth/reset" className="f-w-400">
                              {" "}
                              Haz clic aquí.
                            </NavLink>
                          </div>
                        </div>
                      </>
                    )}
                    {showError.length > 0 ? (
                      <Alert variant={varianteMensaje}>{showError}</Alert>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ValidationForm>
    </Aux>
  );
};

// export default connect(null, { userLogged })(Login);
export default Login;
