import React from 'react';

import './MiniLoader.css';
 
  
export default function MiniLoader({ variant = 'dark' }) {
  const cssLoader= variant === "dark" ? "lds-darkMini" : variant === "light" ? "lds-lightMini" : "lds-darkMini";

  return (
    <div className={`lds-gridMini ${cssLoader}`}>
    <div />
    <div />
    <div />
  </div>
  )
}

