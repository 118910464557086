import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Button, Modal } from "react-bootstrap";
import Card from "../../App/components/MainCard";
import CambioContrasena from "../../App/components/usuarios/cambioContrasena";
import userContext from "../../context/userContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import "./usuariosContra.css"
import MensajeAlerta from "../../App/components/MensajeAlerta/MensajeAlertaSwal"
import Aux from "../../hoc/_Aux/index";
import { logout } from "../../actions/auth";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";

function UserPassView(props) {
  const dispatch = useDispatch();
  const sweetConfirmHandler = () => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "Su contraseña ha cambiado",
      text: "Inicie sesión nuevamente",
      type: "success",
      showCloseButton: true,
    }).then(() => {
      //localStorage.removeItem("userLogged");
      Cookies.remove("auth");
      dispatch(logout());
      props.history.push("/auth/login");
    });
  };

  const user = useContext(userContext);
  const [onCambioCon, setoncambioCon] = useState(false);

  const onCambioConDone = (val) => setoncambioCon(val);

  const onCerrarModalCambio = () => {
    return false;
  };

  useEffect(() => {
    if (onCambioCon) {
      sweetConfirmHandler();
      /*
       */
    }
  }, [onCambioCon]);
  return (
    <Aux>
            <CambioContrasena
              usuario={{ usuarioId: user.usuarioId }}
              onCambioConDone={onCambioConDone}
              onCerrarModalCambio={onCerrarModalCambio}
              mensajeAlerta={MensajeAlerta}
              {...props}
            />
       

    </Aux>
  );
}

export default UserPassView;
