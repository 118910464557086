import React, { useState,useEffect } from "react";
import { TextInput, ValidationForm } from 'react-bootstrap4-form-validation'
import Aux from '../../hoc/_Aux'
import authLogo from "../../assets/images/logoLight.webp";
import MiniLoader from '../../App/components/Loader/MiniLoader';
import { Alert } from 'react-bootstrap';
import "./../../assets/scss/style.scss";
import "./updatepass.css";
import { NavLink } from "react-router-dom";
import config from "../../config";
import { useForm } from "../../hooks/useForm";
const UpdatePass = ({ match, history }) => {
  let { id } = match.params;
  const [values, , , setValues] = useForm({
    id: id,
    password: '',
    password_confirmar: ''
  });
  const [loading, setloading] = useState(false);
  const [showError, setshowError] = useState("");
  const [varianteMensaje, setvarianteMensaje] = useState("");

  const hanldeOnChangePassword = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
}
const confirmarPassWord = (value) => {
  return value && value === values.password;
};

const handleCancelar = () => {
  history.replace("/auth/login");
}

  const handleSubmitLogin = async (e) => {
    setshowError("");
    setloading(true)
    e.preventDefault();
      fetch(`${config.urlApi}/resetpass`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) =>
          Promise.all([response.ok, response.json(), response.status])
        )
        .then(([responseOk, body, status]) => {
          if (responseOk) {
            setValues({
              id: id,
              password: '',
              password_confirmar: ''
            });
            let { code, data } = body.data;
            if (code === 1) {
              setvarianteMensaje("success");
              setshowError(data);
            } else {
              setvarianteMensaje("danger");
              setshowError(data);
            }
            setloading(false)
          } else {
            setloading(false)
            setshowError(body.data);
          }
        })
        .catch((err) => {
          setloading(false)
          setvarianteMensaje("danger");
          setshowError(
            "Error con la comunicacion, comuniquese con el administrador."
          );
        });

  };

  const handleErrorSubmit = (e, formData, errorInputs) => {
    setvarianteMensaje("");
    setshowError("");
  };


  return (
    <Aux>
      <ValidationForm
        onSubmit={handleSubmitLogin}
        onErrorSubmit={handleErrorSubmit}
        setFocusOnError
        defaultErrorMessage={{ required: "El campo es requerido.", minLength: "Ingresar por lo menos {minLength} caracteres" }}
      >
        <div className="auth-wrapper">

          <div className="auth-content">
            <div className="card cardLogin">
              <div className="row align-items-center text-center">
                <div className="col-md-12">
                  <div className="card-body">
                    <img src={authLogo} alt="" className="img-fluid mb-4 imgLogoLogin" style={{ width: "70%" }} ></img>
                    <h4 className="mb-3 f-w-400">Actualizar Contraseña</h4>

                    <div>
                      <div className="input-group mb-3">
                        <TextInput
                          className="form-control form-controlLogin rounded-pill"
                          name="password"
                          type="password"
                          id="password"
                          placeholder="Nueva"
                          required
                          pattern="(?=.*[A-Z]).{6,}"
                          errorMessage={{ required: "Ingrese la nueva contraseña", pattern: "La contraseña debe de tener al menos 6 caracteres y contener al menos una letra mayúscula" }}
                          value={values.password}
                          onChange={hanldeOnChangePassword}
                        />

                      </div>
                      <div className="input-group mb-3">
                        <TextInput
                          className="form-control form-controlLogin rounded-pill"
                          name="password_confirmar"
                          id="password_confirmar"
                          type="password"
                          placeholder="Confirmar"
                          required
                          validator={confirmarPassWord}
                          errorMessage={{ required: "Por favor confirme la nueva contraseña", validator: "La contraseña no coincide" }}
                          value={values.password_confirmar}
                          onChange={hanldeOnChangePassword}
                          autoComplete="off"
                        />

                      </div>
                    </div>
                    <div className="form-group text-left mt-2 "></div>
                    {loading ? <MiniLoader></MiniLoader> :
                      <>
                        <button className="btn btn-block btn-success m-0 btn-login text-dark rounded-pill">
                        Actualizar
                        </button>
                        <div className="form-group text-left mt-2 "></div>
                        <button className="btn btn-block btn-primary btn-cancelarResetPass" type="button" onClick={handleCancelar}>
                        Cancelar
                        </button>
                      </>
                    }
                    {varianteMensaje}
                    {showError.length > 0 ? (
                      <Alert variant={varianteMensaje}>{showError}</Alert>
                    ) : (
                      <p></p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ValidationForm>
    </Aux >
  )
}
export default UpdatePass;
