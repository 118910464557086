import config from '../config';
var CryptoJS = require("crypto-js");
export var Encrypt = (data) => {
    let texto = CryptoJS.AES.encrypt(String(data), config.keyCryptoJS).toString();
    texto = texto.replaceAll('/', '--x');
    return texto;
}
export var Decrypt = (data) => {
    data = data.replaceAll('--x', '/');
    var texto = CryptoJS.AES.decrypt(String(data), config.keyCryptoJS);
    return JSON.parse(texto.toString(CryptoJS.enc.Utf8));
}
