import React, { useEffect } from "react";
import Aux from "../../hoc/_Aux/index";
import FormularioPagoPrincipalV2 from "../../App/components/FormularioPago/FormularioPagoPrincipalV2";

function FormularioPago({ match, history }) {
  let { clienteId } = match.params;
  return (
    <Aux>
      <FormularioPagoPrincipalV2
        clienteId={!(clienteId === undefined) ? clienteId : null}
      ></FormularioPagoPrincipalV2>
    </Aux>
  );
}

export default FormularioPago;
