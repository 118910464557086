import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import Loadable from 'react-loadable';
import Loader from '../App/layout/Loader'
import Login from '../Views/login/login';
import ResetPass from '../Views/resetpass/resetpass';
import UpdatePass from '../Views/updatepass/updatepass';
import {NotFound} from '../App/components/NotFound';
import config from '../config';
import UserPassView from '../Views/usuarios/usuarioContra'
import FormularioPago from '../Views/FormularioPago/FormularioPago';
const AdminLayout = Loadable({
    loader: () => import('../App/layout/AdminLayout'),
    loading: Loader
});

const Rutas = () => {
    return (
        <BrowserRouter basename={config.basename}>
            <Switch>
                <Route exact path="/" component={Login}/>
                <Route exact path="/auth/login" component={Login}/>
                <Route exact path="/auth/reset" component={ResetPass}/>
                <Route exact path="/admin/usuariospass" component={UserPassView}/>
                <Route exact path="/admin/formularioPago" component={FormularioPago}/>
                <Route exact path="/auth/updatepass/:id" component={UpdatePass}/>
                <Route path="/socio" component={AdminLayout} />
                <Route component={NotFound}/>
            </Switch>
        </BrowserRouter>
    )
}

export default Rutas;
