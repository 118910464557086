const DEMO = {
    BLANK_LINK: "#" ,
    PASS_CHANGE: "/usuarios"
};

const CONSTANTE = {
    BLANK_LINK: "#",
    PASS_CHANGE: "/usuarios",
};

export default {DEMO, CONSTANTE};