import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import "../FormularioPago.css";
import userContext from "../../../../context/userContext";

import { Card, Form, Col, Row, Button } from "react-bootstrap";

import MensajeAlertaSwal from "../../MensajeAlerta/MensajeAlertaSwal";
import {
  ValidationForm,
  TextInput,
  SelectGroup,
  Checkbox
} from "react-bootstrap4-form-validation";
import {
  format,
  upperFirstLetter,
  getWindowDimensions
} from "../../../../utils/functions";
import ReactTooltip from "react-tooltip";
import unitedstates from "../../../../assets/images/portafolio/icons/united-states-logo.png";
import dividendos from "../../../../assets/images/portafolio/images/Dividendos.png";
import bank from "../../../../assets/images/portafolio/icons/bank_icon.png";
import banktransfer from "../../../../assets/images/portafolio/icons/backtransfer_icon.png";
import pd_icon from "../../../../assets/images/portafolio/icons/pd_icon.png";
import globo from "../../../../assets/images/portafolio/icons/internet-logo.png";
import payoneer from "../../../../assets/images/portafolio/icons/Payoneer-logo.png";
import "./Dividendos_socio.css";
import callApi from "../../../../helpers/conectorApi";
import { Encrypt } from "../../../../helpers/myCryptoJS";
export default function Dividendos_socio(props) {
  const [opcionDividendo, setopcionDividendo] = useState(
    props.datos.decisionDividendos === "Reinventir dividendos"
      ? 1
      : props.datos.decisionDividendos === "Acumular en cuenta de bando de LLC"
      ? 2
      : (props.datos.decisionDividendos === "Transferencia bancaria" ||
        props.datos.decisionDividendos === "Transferencia bancaria periodo actual")
      ? 3
      : props.datos.decisionDividendos ===
        "Transferencia bancaria acumulada y periodo actual"
      ? 4
      : null
  );

  const [dividendosTotalesSum, setdividendosTotalesSum] = useState(" 00.00");
  const onClickEvent = (e) => {
    setopcionDividendo(e);
  };
  const SpanDark = styled.div`
    border-radius: 20px;
    background-color: #171332;
    width: 100%;
    padding: 20px;
    max-width: 800px;
    p {
      margin-bottom: 0px;
      font-size: 16px;
    }
    .pTitulo {
      color: #3cdfb3;
      font-weight: bolder;
    }
    .pDescripcion {
      text-align: center;
      color: white;
      font-size: 2.5rem;
    }

    @media (max-width: 768px) {
      width: 90%;
    }
  `;

  const SeleccionOpcion = styled.div`
    &:hover {
      box-shadow: 0 5px 11px 0px rgb(69 90 100 / 30%);
      cursor: pointer;
    }
    background: white;
    border-radius: 30px;
    height: 100%;
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 20px;

    img {
      height: 40px;
    }

    p {
      color: #707070;
      font-weight: 400;
      font-size: 13px;
    }
    & > .card {
      margin-bottom: 0px;
    }

    & > .card > .icons-opc {
      font-size: 25px;
      font-weight: 100;
      padding-bottom: 5px;
    }
    & > .card > .text-opc {
      margin: none;
      & b {
        font-weight: 900;
      }
    }
  `;

  const getInversion = async (inversion) => {
    try {
      let response = await callApi(
        `/inversion/${inversion}/dividendos`,
        {},
        true
      );

      if (response.ok === true) {
        let dividends = response.data.map((item) => {
          return {
            id: item.id,
            dividend: item.dividend,
            movementType: item.movementType
          };
        });
        return dividends;
      } else {
        return [];
      }
    } catch (error) {
      return [];
    }
  };
  function getData() {
    var dividendosTotales = [];
    const promises = props.inversiones.map((el) => getInversion(el.id));
    Promise.all(promises).then((data) => {
      data.map((it) => {
        it.map((i) => {
          if (i.movementType !== "Pago realizado") dividendosTotales.push(i);
        });
      });
      if (dividendosTotales.length > 0) {
        var sumatoriaDividendos = 0;
        dividendosTotales.map((item) => {
          sumatoriaDividendos = sumatoriaDividendos + item.dividend;
        });
        setdividendosTotalesSum(format(sumatoriaDividendos, 0));
      }
    });
  }
  useEffect(() => {
    getData();
    return () => {};
  }, []);

  return (
    <Card>
      <Card.Body>
        <Row className="HeaderOPC1">
          <div className="HeaderOPC1_row4">
            <div>
              <img src={dividendos} className="card-img-opc" alt="ACH"></img>{" "}
            </div>
            <div>
              <SpanDark>
                <Row>
                  <Col>
                    <p className="pTitulo">Dividendos Actuales</p>
                    <p className="pDescripcion">${dividendosTotalesSum}</p>
                  </Col>
                </Row>
              </SpanDark>
            </div>{" "}
          </div>
          <div className="HeaderOPC1_row2">
            ¿Qué deseas hacer con tus dividendos?
          </div>
          <div className="HeaderOPC1_row3">
            <p>
              Estimado socio, a continuación encontrarás las opciones
              disponibles para que elijas qué hacer con tus dividendos de
              Portafolio Diversificado para el período del Q2 2023.{" "}
            </p>
            <p>
              <b>
                *Ojo, aún no transferiremos dividendos. Esto es exclusivamente
                para el período del Q2 2023.
              </b>
            </p>
          </div>
        </Row>
        <Row className="rowOpc">
          <Col className="colOpcion">
            <SeleccionOpcion
              id="opc1"
              className={opcionDividendo === 1 ? "border border-primary" : ""}
              onClick={(e) => {
                let result = {
                  decisionDividendos: "Reinventir dividendos"
                };
                props.updateDatos(result);
                onClickEvent(1);
              }}
            >
              <Card className="d-flex justify-content-center">
                <div className="icons-opc">
                  <img src={pd_icon} className="card-img-opc" alt="PD"></img>
                </div>
                <div className="text-opc">
                  <p className="">
                    Reinvertir tus dividendos en múltiplos de $1,000 USD en
                    Compound
                  </p>

                  <a data-for="reinvertir" data-tip="1">
                    ¿Cómo funciona?
                  </a>
                  <ReactTooltip
                    className={"tooltip-dividendos"}
                    id="reinvertir"
                    getContent={(dataTip) => (
                      <div>
                        <p className="tooptip-title">
                          Genera nuevos intereses añadiendo tus dividendos a tu
                          inversión inicial
                        </p>
                        <p className="tooptip-body">
                          Si seleccionas esta opción, tus dividendos serán
                          reinvertidos en nuestra compañía Portafolio
                          Diversificado Compound, donde tenemos un holding de
                          propiedades en Cleveland. Si quieres saber más ingresa
                          a:
                          <a
                            href="https://www.portafoliodiversificado.com/reinversion"
                            Target="_blank"
                          >
                            {" "}
                            www.portafoliodiversificado.com/reinversión
                          </a>
                        </p>
                      </div>
                    )}
                    effect="solid"
                    delayHide={500}
                    delayShow={500}
                    delayUpdate={500}
                    place={"right"}
                    border={true}
                    type={"light"}
                    globalEventOff="click"
                  />
                </div>
              </Card>
            </SeleccionOpcion>
          </Col>
          <Col className="colOpcion">
            <SeleccionOpcion
              id="opc2"
              className={opcionDividendo === 2 ? "border border-primary" : ""}
              onClick={(e) => {
                let result = {
                  decisionDividendos: "Acumular en cuenta de bando de LLC"
                };
                props.updateDatos(result);
                onClickEvent(2);
              }}
            >
              <Card className="d-flex justify-content-center">
                <div className="icons-opc">
                  <img src={bank} className="card-img-opc" alt="Bank"></img>
                </div>
                <div className="text-opc">
                  <p className="">
                    Dejarlos acumulando en la cuenta de banco de la LLC (no
                    genera intereses)
                  </p>
                  <a data-for="acumula" data-tip="2">
                    ¿Cómo funciona?
                  </a>
                  <ReactTooltip
                    className={"tooltip-dividendos"}
                    id="acumula"
                    getContent={(dataTip) => (
                      <div>
                        <p className="tooptip-title"></p>
                        <p className="tooptip-body">
                          Si seleccionas esta opción, el dinero se quedará
                          guardado en la cuenta de banco en Estados Unidos, sin
                          embargo no generará interés.
                        </p>
                      </div>
                    )}
                    effect="solid"
                    delayHide={1000}
                    delayShow={500}
                    delayUpdate={500}
                    place={"right"}
                    border={true}
                    type={"light"}
                    globalEventOff="click"
                  />
                </div>
              </Card>
            </SeleccionOpcion>
          </Col>

          <Col className="colOpcion">
            <SeleccionOpcion
              id="opc3"
              className={opcionDividendo === 3 ? "border border-primary" : ""}
              onClick={(e) => {
                let result = {
                  decisionDividendos: "Transferencia bancaria periodo actual"
                };
                props.updateDatos(result);
                onClickEvent(3);
              }}
            >
              <Card className="d-flex justify-content-center">
                <div className="icons-opc">
                  <img
                    src={banktransfer}
                    className="card-img-opc"
                    alt="Bank transfer"
                  ></img>
                </div>
                <div className="text-opc">
                  <p className="">
                    Transferencia bancaria a la cuenta antes confirmada de
                    dividendos del <b>periodo actual</b>.
                  </p>
                </div>
              </Card>
            </SeleccionOpcion>
          </Col>

          <Col className="colOpcion">
            <SeleccionOpcion
              id="opc4"
              className={opcionDividendo === 4 ? "border border-primary" : ""}
              onClick={(e) => {
                let result = {
                  decisionDividendos:
                    "Transferencia bancaria acumulada y periodo actual"
                };
                props.updateDatos(result);
                onClickEvent(4);
              }}
            >
              <Card className="d-flex justify-content-center">
                <div className="icons-opc">
                  <img
                    src={banktransfer}
                    className="card-img-opc"
                    alt="Bank transfer"
                  ></img>
                </div>
                <div className="text-opc">
                  <p className="">
                    Transferencia bancaria a la cuenta antes confirmada de
                    dividendos <b>retenidos y del periodo actual.</b>.
                  </p>
                </div>
              </Card>
            </SeleccionOpcion>
          </Col>
        </Row>
        <Row>
          {" "}
          <Col className="d-flex justify-content-left">
            <Button
              id="btnBack"
              name="btnBack"
              className="form-buttonPortafolio font-weight-bolder text-dark ml-0"
              key="btnBack"
              onClick={props.handleBackClick}
              variant="outline-success"
              size="md"
            >
              Atrás
            </Button>
            <Button
              id="btnGuardar"
              className="form-buttonPortafolioDark font-weight-bolder"
              name="btnGuardar"
              key="btnGuardar"
              type="submit"
              size="md"
            >
              Guardar
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
