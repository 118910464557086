import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import encabezado from "../../../assets/images/portafolio/images/Encabezado.png";
import guatemala from "../../../assets/images/portafolio/images/guatemala.png";
import bank from "../../../assets/images/portafolio/images/bank.png";
import unitedstates from "../../../assets/images/portafolio/icons/united-states-logo.png";
import globo from "../../../assets/images/portafolio/icons/internet-logo.png";
import payoneer from "../../../assets/images/portafolio/icons/Payoneer-logo.png";
import { Card, Form, Col, Row, Button } from "react-bootstrap";
import Loader from "../Loader/Loader";
import coverVideo from "../../../assets/images/portafolio/images/Actualización de datos.jpg";

import moment from "moment";
import dividendos_video from "../../../assets/videos/dividendos.mp4";
import MensajeAlertaSwal from "../MensajeAlerta/MensajeAlertaSwal";
import axios from "axios";
import { useSelector } from "react-redux";
import profesiones_list from "../../../utils/profesiones.json";

import config from "../../../config";
import "./FormularioPago.css";
import userContext from "../../../context/userContext";
import {
  ValidationForm,
  SelectGroup,
  TextInput
} from "react-bootstrap4-form-validation";
import Pago_ach_usa_pay from "./TiposPago/Pago_ach_usa_pay";
import Pago_transferencia from "./TiposPago/Pago_transferencia";
import Dividendos_socio from "./TiposPago/Dividendos_socio";
import callApi from "../../../helpers/conectorApi";
import { Encrypt } from "../../../helpers/myCryptoJS";
import Datetime from "react-datetime";
import { renderInputFecha, validDate } from "../Utils/fechas";
import { file } from "jszip";

const HeaderFormulario = styled.div`
  color: white;
  margin-left: -300px;
  margin-right: -25px;
  margin-top: -25px;
  margin-bottom: 10px;
`;
const EncabezadoImg = styled.img`
  width: 100%;
`;

const HeaderFormularioTitle = styled.p`
  font-size: 45px;
  font-weight: 500;
  margin-left: 300px;
  margin-right: 25px;
  @media (max-width: 621px) {
    font-size: 20px;
  }
`;
const HeaderFormularioTitleSub = styled.p`
  font-size: 20px;
  margin-left: 300px;
  margin-right: 25px;
  @media (max-width: 621px) {
    font-size: 10px;
  }
`;

const BodyFormulario = styled.div`
  height: 80%;
  width: 100%;
`;

const BodyLabelFormulario = styled.div`
  width: 75%;
  max-width: 800px;
  text-align: center;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const BodyData = styled.div`
  width: 80%;
  max-width: 800px;

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const FormulariosGeneral = styled.div`
  padding-top: 10px;

  .card {
    background-color: transparent;
    box-shadow: none;
  }

  .card > .card-body {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;
const SeleccionOpcion = styled.div`
  &:hover {
    box-shadow: 0 5px 11px 0px rgb(69 90 100 / 30%);
    cursor: pointer;
  }
  background: white;
  border-radius: 30px;
  height: 100%;
  padding-top: 20px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;

  img {
    height: 40px;
  }

  p {
    color: #707070;
    font-weight: 300;
    font-size: 13px;
  }
  & > .card {
    margin-bottom: 0px;
  }

  & > .card > .icons-opc {
    font-size: 25px;
    font-weight: 100;
    padding-bottom: 5px;
  }
  & > .card > .text-opc {
    margin: none;
    & b {
      font-weight: 500;
    }
  }
`;

const SpanDark = styled.div`
  border-radius: 20px;
  background-color: #171332;
  width: 80%;
  padding-top: 20px;
  padding-bottom: 20px;
  max-width: 800px;
  p {
    margin-bottom: 0px;
    font-size: 16px;
  }
  .pTitulo {
    color: #3cdfb3;
    font-weight: bolder;
  }
  .pDescripcion {
    color: white;
  }

  @media (max-width: 768px) {
    width: 90%;
  }
`;
function FormularioPagoPrincipalV2(props) {
  const { token, userInfo: user } = useSelector((state) => state);
  const configReq = {
    headers: { Authorization: `Bearer ${user.token}` }
  };
  const [loading, setloading] = useState(false);
  const [opcionPago, setopcionPago] = useState("");
  const [updateAction, setupdateAction] = useState(false);
  const [page, setpage] = useState(1);
  const [error, setError] = useState(false);
  const [inversiones, setInversiones] = useState([]);
  const [nombreCompleto, setnombreCompleto] = useState();
  const [datos, setDatos] = useState({
    id: null,
    opcionPago: null,
    decisionDividendos: null,

    nombrelegal_t: null,
    email_t: null,
    numerocuenta_t: null,
    tipoCuenta_t: null,
    nombreBanco_t: null,
    tipoBeneficiario_t: null,
    pais_t: null,
    telefono_t: null,
    direccionlegal_t: null,
    direccioncalle_t: null,
    ciudad_t: null,
    estado_t: null,
    codigopostal_t: null,
    confirmacion_t: false,
    codigo_swift_t: null,
    iban_t: null,

    nombrelegal: null,
    email: null,
    routing: null,
    numerocuenta: null,
    nombreBanco: null,
    tipoCuenta: null,
    pais: null,
    direccion1: null,
    direccion2: null,
    ciudad: null,
    estado: null,
    codigopostal: null,
    confirmacion: false,
    actualizado: false,

    Nombre: null,
    Apellido: null,
    Profesion: null,
    especialidad_profesion: null,
    Nacionalidad: null,
    whatsapp: null,
    linkedin: null,
    redsocial: null,
    frecuencia_comunicacion: null,
    emergencia_contacto_nombre: null,
    emergencia_contacto_correo: null,
    emergencia_contacto_telefono: null,
    fechanacimiento: null,
    archivo: null
  });
  const handleClick = (e, formData, inputs) => {
    e.preventDefault();

    if (page === 2) {
      if (opcionPago !== null && opcionPago.length > 0) {
        setpage(3);
      } else {
        MensajeAlertaSwal(
          "Formulario de pago",
          "Seleccione una opción de pago",
          "error"
        );
      }
    }
    if (page === 3) {
      setpage(4);
    }
    if (page === 4) {
      setpage(5);
    } else if (page == 5) {
      if (
        datos.decisionDividendos == "-" ||
        datos.decisionDividendos == null ||
        datos.decisionDividendos <= 0
      ) {
        MensajeAlertaSwal(
          "Formulario de pago",
          "¿Qué deseas hacer con tus dividendos?",
          "error"
        );
      } else {
        let fileInfo = uploadFileToS3(datos.file_data);
      }
    }
  };

  const handleErrorSubmit = (e, formData, errorInputs) => {
    e.preventDefault();
    if (page === 2) {
      MensajeAlertaSwal(
        "Formulario de pago",
        "Ingrese los campos requeridos",
        "error"
      );
    }
  };

  const onClickEvent = (e) => {
    if (!error) {
      setopcionPago(e);
      setDatos({ ...datos, opcionPago: e });

      if (nombreCompleto) {
        if (nombreCompleto) {
          //setpage(3);
        } else {
          MensajeAlertaSwal(
            "Formulario de pago",
            "Ingrese nombre completo",
            "error"
          );
        }
      }
    }
  };

  const handleBackClick = () => {
    if (page === 2) {
      setpage(1);
    } else if (page === 3) {
      setpage(2);
    } else if (page === 4) {
      setpage(3);
    } else if (page === 5) {
      setpage(4);
    }
  };

  const updateDatos = (data) => {
    let result = {
      ...datos,
      ...data
    };
    setDatos(result);
  };

  const handleNextClick = () => {
    if (datos) {
      if (page === 1) {
        setpage(2);
      } else if (page === 2) {
        if (nombreCompleto) {
          if (opcionPago !== null && opcionPago.length > 0) {
            setpage(3);
          } else {
            MensajeAlertaSwal(
              "Formulario de pago",
              "Seleccione una opción de pago",
              "error"
            );
          }
        } else {
          MensajeAlertaSwal(
            "Formulario de pago",
            "Ingrese nombre completo",
            "error"
          );
        }
      } else if (page === 3) {
        setpage(4);
      } else if (page === 4) {
        setpage(5);
      }
    }
  };
  const handleInputChange = (
    event,
    campoEspecial = false,
    tipoCampo = null,
    campoName = null,
    deptoSufijo = null,
    deptoprefijo = ""
  ) => {
    if (campoEspecial === false) {
      setDatos({
        ...datos,
        [event.target.name]: event.target.value
      });
    } else {
      switch (tipoCampo) {
        case "depto":
          let campo1 = `${deptoprefijo}depto${deptoSufijo}`;
          let campo2 = `${deptoprefijo}muni${deptoSufijo}`;
          setDatos({
            ...datos,
            [campo1]: event.target.value,
            [campo2]: ""
          });
          break;

        case "multi":
          setDatos({
            ...datos,
            [campoName]: event
          });
          break;
        case "check":
          setDatos({
            ...datos,
            [campoName]: event.target.checked
          });
          break;
        default:
        // code block
      }
    }
  };

  useEffect(() => {
    if (!props.clienteId) {
      window.location.href = "/socio/home";
    } else {
      getData();
      getCliente(props.clienteId);
    }
    return () => {};
  }, []);

  const createFormaPago = async (fileData) => {
    try {
      var data = {
        ...datos,
        ...fileData,
        actualizado: "true",
        opcionPago: datos.opcionPago,
        confirmacion_t: datos.confirmacion_t ? "true" : "false",
        confirmacion: datos.confirmacion ? "true" : "false",
        clienteId: props.clienteId,
        nombreCompleto: nombreCompleto,
        Cliente: inversiones
      };
      delete data["file_data"];
      delete data["file_form_name"];
      const res = await axios.post(
        `${config.urlApi}/cliente/formaPago`,

        data,
        configReq
      );
      if (res.status === 202) {
        MensajeAlertaSwal(
          "Formulario de pago",
          `Se ha creado el formulario de pago correctamente`,
          "success"
        );
        redireccionaHome();
      }
    } catch (error) {
      setloading(false);

      if (error.response.status === 400) {
        if (error.response.data.error) {
          const dataError = error.response.data;
          MensajeAlertaSwal(
            "Formulario de pago",
            `[${dataError.codigo}] - ${dataError.data}`,
            "error"
          );
        } else {
          MensajeAlertaSwal(
            "Formulario de pago",
            `[${error.response.status}] - ${error.response.statusText}`,
            "error"
          );
        }
      } else {
        MensajeAlertaSwal(
          "Formulario de pago",
          `[${error.response.status}] - ${error.response.statusText}`,
          "error"
        );
      }
    }
  };

  const updatePersona = async (fileData) => {
    try {
      var data = {
        ...datos,
        actualizado: "true",
        opcionPago: datos.opcionPago,
        confirmacion_t: datos.confirmacion_t ? "true" : "false",
        confirmacion: datos.confirmacion ? "true" : "false",
        nombreCompleto: nombreCompleto,
        Cliente: inversiones
      };
      if (fileData) {
        data = {
          ...data,
          ...fileData
        };
      }
      delete data["file_data"];
      delete data["file_form_name"];
      const res = await axios.put(
        `${config.urlApi}/cliente/formaPago/${Encrypt(data.idCliente)}`,
        data,
        configReq
      );

      if (res.status === 201) {
        MensajeAlertaSwal(
          "Actualizacion forma de pago",
          "Se ha actualizado la forma de pago cliente",
          "success"
        );
        redireccionaHome();
      }
    } catch (error) {
      setloading(false);

      if (error.response.status === 400) {
        if (error.response.data.error) {
          const dataError = error.response.data;
          MensajeAlertaSwal("Actualizacion forma de pago", dataError, "error");
        } else {
          MensajeAlertaSwal(
            "Actualizacion forma de pago",
            `[${error.response.status}] - ${error.response.statusText}`,
            "error"
          );
        }
      } else {
        MensajeAlertaSwal(
          "Actualizacion forma de pago",
          `[${error.response.status}] - ${error.response.statusText}`,
          "error"
        );
      }
    }
  };

  const getData = async () => {
    try {
      var resultFirst;

      const res = await axios.get(
        `${config.urlApi}/usuario/${Encrypt(user.usuarioId)}/clientId`,
        configReq
      );
      if (res.status === 200) {
        if (res.data.data === "No existe el usuario") {
          MensajeAlertaSwal(
            "Error",
            "No se puede obtener informacion de pago del cliente (1)",
            "error"
          );
          setError(true);
          //window.location.href = "/socio/home";
          return;
        }
        if (!(res.data.data === "" || res.data.data === null)) {
          if (res.data.data.paymentInformation.length > 0) {
            resultFirst = {
              id: res.data.data.paymentInformation[0].id,

              clienteId: Encrypt(
                parseInt(res.data.data.paymentInformation[0].idCliente)
              ),
              opcionPago: res.data.data.paymentInformation[0].opcionPago,
              decisionDividendos:
                res.data.data.paymentInformation[0].DecisionDividendos,

              nombrelegal_t: res.data.data.paymentInformation[0].nombrelegal_t,
              email_t: res.data.data.paymentInformation[0].email_t,
              numerocuenta_t:
                res.data.data.paymentInformation[0].numerocuenta_t,
              nombreBanco_t: res.data.data.paymentInformation[0].nombreBanco_t,
              tipoBeneficiario_t:
                res.data.data.paymentInformation[0].tipoBeneficiario_t,
              pais_t: res.data.data.paymentInformation[0].pais_t,
              telefono_t: res.data.data.paymentInformation[0].telefono_t,
              direccionlegal_t:
                res.data.data.paymentInformation[0].direccionlegal_t,
              direccioncalle_t:
                res.data.data.paymentInformation[0].direccioncalle_t,
              ciudad_t: res.data.data.paymentInformation[0].ciudad_t,
              estado_t: res.data.data.paymentInformation[0].estado_t,
              codigopostal_t:
                res.data.data.paymentInformation[0].codigopostal_t,
              confirmacion_t:
                res.data.data.paymentInformation[0].confirmacion_t === "true"
                  ? true
                  : false,
              codigo_swift_t:
                res.data.data.paymentInformation[0].codigo_swift_t,
              iban_t: res.data.data.paymentInformation[0].iban_t,

              nombrelegal: res.data.data.paymentInformation[0].nombrelegal,
              email: res.data.data.paymentInformation[0].Email,
              routing: res.data.data.paymentInformation[0].routing,
              numerocuenta: res.data.data.paymentInformation[0].numerocuenta,
              nombreBanco: res.data.data.paymentInformation[0].nombreBanco,
              tipoCuenta: res.data.data.paymentInformation[0].tipoCuenta,
              tipoCuenta_t: res.data.data.paymentInformation[0].tipoCuenta_t,
              pais: res.data.data.paymentInformation[0].pais,
              direccion1: res.data.data.paymentInformation[0].direccion1,
              direccion2: res.data.data.paymentInformation[0].direccion2,
              ciudad: res.data.data.paymentInformation[0].ciudad,
              estado: res.data.data.paymentInformation[0].estado,
              codigopostal: res.data.data.paymentInformation[0].codigopostal,
              confirmacion:
                res.data.data.paymentInformation[0].confirmacion === "true"
                  ? true
                  : false,
              actualizado:
                res.data.data.paymentInformation[0].actualizado === "true"
                  ? true
                  : false,

              Nombre: res.data.data.paymentInformation[0].Nombre,
              Apellido: res.data.data.paymentInformation[0].Apellido,
              Profesion: res.data.data.paymentInformation[0].Profesion,
              especialidad_profesion:
                res.data.data.paymentInformation[0].especialidad_profesion,
              Nacionalidad: res.data.data.paymentInformation[0].Nacionalidad,
              whatsapp: res.data.data.paymentInformation[0].whatsapp,
              linkedin: res.data.data.paymentInformation[0].linkedin,
              redsocial: res.data.data.paymentInformation[0].redsocial,
              frecuencia_comunicacion:
                res.data.data.paymentInformation[0].frecuencia_comunicacion,
              emergencia_contacto_nombre:
                res.data.data.paymentInformation[0].emergencia_contacto_nombre,
              emergencia_contacto_correo:
                res.data.data.paymentInformation[0].emergencia_contacto_correo,
              emergencia_contacto_telefono:
                res.data.data.paymentInformation[0]
                  .emergencia_contacto_telefono,
              archivo: res.data.data.paymentInformation[0].archivo,
              fechanacimiento: res.data.data.paymentInformation[0]
                .fechanacimiento
                ? moment(
                    res.data.data.paymentInformation[0].fechanacimiento
                  ).format("yyyy-MM-DD")
                : null
            };

            setDatos(resultFirst);
            setopcionPago(res.data.data.paymentInformation[0].opcionPago);
            setupdateAction(true);
          } else {
            setupdateAction(false);
          }
        }
      }
    } catch (error) {
      if (error.response.status === 400) {
        if (error.response.data.error) {
          const dataError = error.response.data.data;
          MensajeAlertaSwal(
            "No se puede obtener informacion de pago del cliente",
            `${dataError}`,

            "error"
          );
        } else {
          MensajeAlertaSwal(
            "No se puede obtener informacion de pago del cliente",
            `[${error.response.status}] - ${error.response.statusText}`,
            "error"
          );
        }
      } else {
        MensajeAlertaSwal(
          "No se puede obtener informacion de pago del cliente",
          `[${error.response.status}] - ${error.response.statusText}`,
          "error"
        );
      }
    }
  };
  const getCliente = async (pidCliente) => {
    try {
      let response = await callApi(`/cliente/${pidCliente}`, {}, true);

      if (response.ok === true) {
        let Investments = response.data.investments.map((item) => {
          return { id: item };
        });
        setInversiones(Investments);
        setnombreCompleto(response.data.fullName);
      } else {
      }
    } catch (error) {}
  };

  const uploadFileToS3 = async (data) => {
    try {
      setloading(true);
      if (data) {
        const res = await axios.post(
          `${config.urlApi}/formaPago/archivo`,
          data,
          configReq
        );
        if (res.status === 202) {
          MensajeAlertaSwal(
            "Subir archivo",
            `Se ha almacenado el archivo  correctamente`,
            "success"
          );
          if (updateAction) {
            updatePersona({
              archivo: res.data.data.url
            });
          } else {
            createFormaPago({
              archivo: res.data.data.url
            });
          }
        }
      } else {
        updatePersona(null);
      }
      setloading(false);
      /*
      
      */
    } catch (error) {
      //setloading(false);
      if (error.response) {
        if (error.response.status === 500) {
          if (error.response.data.error) {
            const dataError = error.response.data.data;
            MensajeAlertaSwal(
              "Subir archivo",
              `[${dataError.codigo}] - ${dataError.data}`,
              "error"
            );
          } else {
            MensajeAlertaSwal(
              "Subir archivo",
              `[${error.response.status}] - ${error.response.statusText}`,
              "error"
            );
          }
        } else {
          MensajeAlertaSwal(
            "Subir archivo",
            `[${error.response.status}] - ${error.response.statusText}`,
            "error"
          );
        }
      }
    }
  };

  useEffect(() => {
    return () => {};
  }, [datos]);

  const redireccionaHome = () => {
    setTimeout(() => {
      window.location.href = "/socio/home";
    }, 2000);
  };
  return (
    <>
      <HeaderFormulario className="card">
        <EncabezadoImg src={encabezado} className="" alt="a"></EncabezadoImg>
        <div className="card-img-overlay d-flex justify-content-center  HeaderFormularioCls">
          <HeaderFormularioTitleSub>
            {/* 
            ¿Qué deseas hacer con tus dividendos? V2 */}
          </HeaderFormularioTitleSub>
          <HeaderFormularioTitle>
            {/* Transferencia bancaria */}
          </HeaderFormularioTitle>
        </div>
      </HeaderFormulario>
      <ValidationForm
        onSubmit={handleClick}
        onErrorSubmit={handleErrorSubmit}
        immediate={false}
        setFocusOnError
        defaultErrorMessage={{
          required: "El campo es requerido.",
          minLength: "Ingresar por lo menos {minLength} caracteres",
          type: "Ingresar un valor valido para el campo."
        }}
      >
        {page === 1 && (
          <BodyFormulario>
            {/* 
          <Row
            className="d-flex justify-content-center  align-items-end"
            Style=" padding: 20px;"
          >
            
            <SpanDark>
              <Col>
                <p className="pTitulo">ACH dentro de Estados Unidos</p>
                <p className="pDescripcion">
                  Esta opción tiene un costo de $5 USD por transacción.
                </p>
              </Col>
            </SpanDark>
          </Row> */}

            <Row className="d-flex justify-content-center  align-items-end">
              <BodyData>
                <FormulariosGeneral>
                  <Row className="HeaderOPC1">
                    <div className="HeaderOPC1_row1"></div>
                    <div className="HeaderOPC1_row2">
                      ¡Hola Socio! Es momento de actualizar tu información
                    </div>
                    <div className="HeaderOPC1_row3">
                      Mira el siguiente video para que conozcas el proceso.
                    </div>
                  </Row>
                  <Row className="d-flex align-items-center justify-content-center subsection-video-portafolio-dividendo">
                    <div className="embed-responsive embed-responsive-16by9 portafolio-video wrapper">
                      <video
                        className=""
                        src={
                          "https://www.dropbox.com/s/pzlr56u474okysu/Actualizaci%C3%B3n%20Socios.mp4?raw=1"
                        }
                        controls
                        poster={coverVideo}
                        controlsList=" nodownload noremoteplayback"
                        disablePictureInPicture
                        autoPlay={false}
                        loop
                      />
                    </div>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-left">
                      <Button
                        id="btnBack"
                        className="form-buttonPortafolioDark font-weight-bolder ml-0"
                        name="btnBack"
                        key="btnBack"
                        onClick={handleNextClick}
                        size="md"
                        type="submit"
                      >
                        Siguiente
                      </Button>
                    </Col>
                  </Row>
                </FormulariosGeneral>
              </BodyData>
            </Row>
          </BodyFormulario>
        )}
        {page === 2 && (
          <BodyFormulario>
            <Row className="d-flex justify-content-center  align-items-center">
              <BodyLabelFormulario>
                <p>
                  Estimado socio, por favor completa estos campos para
                  actualizar tu información bancaria para el período del Q4
                  2022.{" "}
                </p>{" "}
                <p>
                  <b>
                    *Ojo, aún no transferiremos dividendos. Esto es
                    exclusivamente para el período del Q1 2023.
                  </b>
                </p>
              </BodyLabelFormulario>
              <br></br>
            </Row>
            <Row className="d-flex justify-content-center  align-items-end">
              <BodyData>
                <FormulariosGeneral>
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col xs="12">
                          <Form.Group className="">
                            <Form.Label className="pb-2  font-weight-bolder">
                              Nombre Completo*
                            </Form.Label>

                            <TextInput
                              className="form-control form-controlPortafolioFormulario font-weight-bolder"
                              name="nombreCompleto"
                              id="nombreCompleto"
                              placeholder="Nombre Completo"
                              required
                              type="text"
                              autoComplete="off"
                              disabled
                              value={nombreCompleto ? nombreCompleto : ""}
                            />
                          </Form.Group>
                          <Form.Group className="">
                            <Form.Label className="pb-2  font-weight-bolder">
                              Nombre
                            </Form.Label>

                            <TextInput
                              className="form-control form-controlPortafolioFormulario font-weight-bolder"
                              name="Nombre"
                              id="Nombre"
                              placeholder="Ingresa Nombre"
                              required
                              type="text"
                              autoComplete="off"
                              value={datos.Nombre ? datos.Nombre : ""}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                          <Form.Group className="">
                            <Form.Label className="pb-2  font-weight-bolder">
                              Apellido
                            </Form.Label>

                            <TextInput
                              className="form-control form-controlPortafolioFormulario font-weight-bolder"
                              name="Apellido"
                              id="Apellido"
                              placeholder="Ingresa Apellido"
                              required
                              type="text"
                              autoComplete="off"
                              value={datos.Apellido ? datos.Apellido : ""}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                          <Form.Group className="">
                            <Form.Label className="pb-2  font-weight-bolder">
                              Nacionalidad
                            </Form.Label>

                            <TextInput
                              className="form-control form-controlPortafolioFormulario font-weight-bolder"
                              name="Nacionalidad"
                              id="Nacionalidad"
                              placeholder="Ingresa Nacionalidad"
                              required
                              type="text"
                              autoComplete="off"
                              value={
                                datos.Nacionalidad ? datos.Nacionalidad : ""
                              }
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                          <Form.Group>
                            <Form.Label className="pb-2  font-weight-bolder">
                              Fecha Nacimiento
                            </Form.Label>
                            <TextInput
                              className="form-control form-controlPortafolioFormulario font-weight-bolder"
                              name="fechanacimiento"
                              id="fechanacimiento"
                              placeholder="Ingresa Fecha de nacimiento"
                              required
                              type="date"
                              autoComplete="off"
                              value={
                                datos.fechanacimiento
                                  ? datos.fechanacimiento
                                  : ""
                              }
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                          <Form.Group className="">
                            <Form.Label className="pb-2  font-weight-bolder">
                              Profesión
                            </Form.Label>

                            <SelectGroup
                              className="form-control form-controlPortafolioFormulario"
                              name="Profesion"
                              id="Profesion"
                              defaultValue={datos.Profesion}
                              value={datos.Profesion}
                              onChange={(e) => {
                                handleInputChange(e);
                              }}
                              required
                            >
                              <option key="" value="">
                                ---Seleccione una opcion---
                              </option>{" "}
                              {profesiones_list.profesiones.map((item) => {
                                return (
                                  <option
                                    key={item.profesion}
                                    value={item.profesion}
                                  >
                                    {item.profesion}
                                  </option>
                                );
                              })}
                            </SelectGroup>
                          </Form.Group>
                          <Form.Group className="">
                            <Form.Label className="pb-2  font-weight-bolder">
                              Especialidad de profesión
                            </Form.Label>

                            <TextInput
                              className="form-control form-controlPortafolioFormulario font-weight-bolder"
                              name="especialidad_profesion"
                              id="especialidad_profesion"
                              placeholder="Ingresa Especialidad de profesión"
                              required
                              type="text"
                              autoComplete="off"
                              value={
                                datos.especialidad_profesion
                                  ? datos.especialidad_profesion
                                  : ""
                              }
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                          <Form.Group className="">
                            <Form.Label className="pb-2  font-weight-bolder">
                              Whatsapp con código de país
                            </Form.Label>

                            <TextInput
                              className="form-control form-controlPortafolioFormulario font-weight-bolder"
                              name="whatsapp"
                              id="whatsapp"
                              placeholder="Ingresa Whatsapp con código de país"
                              required
                              type="text"
                              autoComplete="off"
                              value={datos.whatsapp ? datos.whatsapp : ""}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                          <Form.Group className="">
                            <Form.Label className="pb-2  font-weight-bolder">
                              Link de linkedIn
                            </Form.Label>

                            <TextInput
                              className="form-control form-controlPortafolioFormulario font-weight-bolder"
                              name="linkedin"
                              id="linkedin"
                              placeholder="Ingresa link de LinkedIn"
                              type="text"
                              autoComplete="off"
                              value={datos.linkedin ? datos.linkedin : ""}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                          <Form.Group className="">
                            <Form.Label className="pb-2  font-weight-bolder">
                              Red Social
                            </Form.Label>

                            <TextInput
                              className="form-control form-controlPortafolioFormulario font-weight-bolder"
                              name="redsocial"
                              id="redsocial"
                              placeholder="Ingresa link de Red Social"
                              type="text"
                              autoComplete="off"
                              value={datos.redsocial ? datos.redsocial : ""}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                          <Form.Group className="">
                            <Form.Label className="pb-2  font-weight-bolder">
                              Frecuencia de comunicacion esperada
                            </Form.Label>

                            <SelectGroup
                              className="form-control form-controlPortafolioFormulario"
                              name="frecuencia_comunicacion"
                              id="frecuencia_comunicacion"
                              defaultValue={datos.frecuencia_comunicacion}
                              value={datos.frecuencia_comunicacion}
                              onChange={(e) => {
                                handleInputChange(e);
                              }}
                              required
                            >
                              <option key="" value="">
                                ---Seleccione una opcion---
                              </option>{" "}
                              <option key="Quincenal" value="Quincenal">
                                Quincenal
                              </option>
                              <option key="Mensual" value="Mensual">
                                Mensual
                              </option>
                              <option key="Trimestral" value="Trimestral">
                                Trimestral
                              </option>
                              <option key="Semestral" value="Semestral">
                                Semestral
                              </option>
                              <option key="Anual" value="Anual">
                                Anual
                              </option>
                              <option
                                key="Contacto minimo"
                                value="Contacto minimo"
                              >
                                Contacto minimo
                              </option>
                            </SelectGroup>
                          </Form.Group>{" "}
                        </Col>
                      </Row>
                      <Row className="rowOpc">
                        <Col className="colOpcion">
                          <Form.Group className="">
                            <Form.Label className="pb-2  font-weight-bolder label_justify">
                              Más adelante en el formulario te vamos a pedir tu
                              preferencia de qué hacer con tus dividendos cada
                              trimestre (dejarlos en la cuenta para que se
                              acumulen, transferencia cada trimestre, o
                              reinversión automática en PD Compound). Sin
                              embargo, de todas formas necesitamos tener tu
                              cuenta de banco en nuestros archivos. Por favor,
                              llénala a continuación.
                            </Form.Label>
                          </Form.Group>
                        </Col>
                        <Col className="colOpcion">
                          <SeleccionOpcion
                            id="opc1"
                            className={
                              opcionPago ===
                              "Transferencia ACH en Estados Unidos"
                                ? "border border-primary"
                                : ""
                            }
                            onClick={(e) => {
                              onClickEvent(
                                "Transferencia ACH en Estados Unidos"
                              );
                            }}
                          >
                            <Card className="d-flex justify-content-center">
                              <div className="icons-opc">
                                <img
                                  src={unitedstates}
                                  className="card-img-opc"
                                  alt="ACH"
                                ></img>{" "}
                                /
                                <img
                                  src={payoneer}
                                  className="card-img-opc"
                                  alt="ACH"
                                ></img>
                              </div>
                              <div className="text-opc">
                                <p className="text-center">
                                  <b>Transferencia ACH en Estados Unidos</b>{" "}
                                  (comisión: $5 USD) <b>/ Payoneer </b>(gratis)
                                </p>
                              </div>
                            </Card>
                          </SeleccionOpcion>
                        </Col>
                        <Col className="colOpcion">
                          <SeleccionOpcion
                            id="opc2"
                            className={
                              opcionPago === "Transferencia Internacional"
                                ? "border border-primary"
                                : ""
                            }
                            onClick={(e) => {
                              onClickEvent("Transferencia Internacional");
                            }}
                          >
                            <Card className="d-flex justify-content-center">
                              <div className="icons-opc">
                                <img
                                  src={globo}
                                  className="card-img-opc"
                                  alt="ACH"
                                ></img>{" "}
                              </div>
                              <div className="text-opc">
                                <p className="text-center">
                                  <b>
                                    Transferencia Internacional - Incluye
                                    Guatemala
                                  </b>{" "}
                                  (comisión: $20 USD)
                                </p>
                              </div>
                            </Card>
                          </SeleccionOpcion>
                        </Col>
                      </Row>
                      <Row className="rowOpc">
                        <Col className="colOpcion">
                          {" "}
                          <Form.Group className="">
                            <Form.Label className="font-size-subtitle">
                              Contacto de emergencia
                            </Form.Label>
                            <hr></hr>
                          </Form.Group>
                          <Form.Group className="">
                            <Form.Label className="pb-2  font-weight-bolder">
                              Nombre completo
                            </Form.Label>

                            <TextInput
                              className="form-control form-controlPortafolioFormulario font-weight-bolder"
                              name="emergencia_contacto_nombre"
                              id="emergencia_contacto_nombre"
                              placeholder="Ingresa Nombre del contacto de emergencia"
                              required
                              type="text"
                              autoComplete="off"
                              value={
                                datos.emergencia_contacto_nombre
                                  ? datos.emergencia_contacto_nombre
                                  : ""
                              }
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                          <Form.Group className="">
                            <Form.Label className="pb-2  font-weight-bolder">
                              Correo electrónico
                            </Form.Label>

                            <TextInput
                              className="form-control form-controlPortafolioFormulario font-weight-bolder"
                              name="emergencia_contacto_correo"
                              id="emergencia_contacto_correo"
                              placeholder="Ingresa Correo electrónico de emergencia"
                              required
                              type="email"
                              autoComplete="off"
                              value={
                                datos.emergencia_contacto_correo
                                  ? datos.emergencia_contacto_correo
                                  : ""
                              }
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                          <Form.Group className="">
                            <Form.Label className="pb-2  font-weight-bolder">
                              Teléfono
                            </Form.Label>
                            <TextInput
                              className="form-control form-controlPortafolioFormulario font-weight-bolder"
                              name="emergencia_contacto_telefono"
                              id="emergencia_contacto_telefono"
                              placeholder="Ingresa Teléfono de emergencia"
                              required
                              type="text"
                              autoComplete="off"
                              value={
                                datos.emergencia_contacto_telefono
                                  ? datos.emergencia_contacto_telefono
                                  : ""
                              }
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        </Col>{" "}
                      </Row>
                      {!error && (
                        <Row>
                          <Col className="d-flex justify-content-center pt-5">
                            <Button
                              id="btnCloseCon"
                              name="btnCloseCon"
                              className="form-buttonPortafolio font-weight-bolder text-dark ml-0"
                              key="btnCloseCon"
                              onClick={handleBackClick}
                              variant="outline-success"
                              size="md"
                            >
                              Atrás
                            </Button>
                            <Button
                              id="btnSiguiente"
                              className="form-buttonPortafolioDark font-weight-bolder ml-0"
                              name="btnSiguiente"
                              key="btnSiguiente"
                              type="submit"
                              size="md"
                            >
                              Siguiente
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Card.Body>
                  </Card>
                </FormulariosGeneral>
              </BodyData>
            </Row>
          </BodyFormulario>
        )}
        {page === 3 && (
          <BodyFormulario>
            {/* 
            <Row
              className="d-flex justify-content-center  align-items-end"
              Style=" padding: 20px;"
            >
              
              <SpanDark>
                <Col>
                  <p className="pTitulo">ACH dentro de Estados Unidos</p>
                  <p className="pDescripcion">
                    Esta opción tiene un costo de $5 USD por transacción.
                  </p>
                </Col>
              </SpanDark>
            </Row> */}
            <Row className="d-flex justify-content-center  align-items-end">
              <BodyData>
                <FormulariosGeneral>
                  {opcionPago === "Transferencia ACH en Estados Unidos" ? (
                    <Pago_ach_usa_pay
                      datos={datos}
                      handleInputChange={handleInputChange}
                      handleBackClick={handleBackClick}
                      handleNextClick={handleNextClick}
                      updateDatos={updateDatos}
                    ></Pago_ach_usa_pay>
                  ) : (
                    <Pago_transferencia
                      datos={datos}
                      handleInputChange={handleInputChange}
                      handleBackClick={handleBackClick}
                      handleNextClick={handleNextClick}
                      updateDatos={updateDatos}
                    ></Pago_transferencia>
                  )}
                </FormulariosGeneral>
              </BodyData>
            </Row>
          </BodyFormulario>
        )}

        {page === 4 && (
          <BodyFormulario>
            {/* 
          <Row
            className="d-flex justify-content-center  align-items-end"
            Style=" padding: 20px;"
          >
            
            <SpanDark>
              <Col>
                <p className="pTitulo">ACH dentro de Estados Unidos</p>
                <p className="pDescripcion">
                  Esta opción tiene un costo de $5 USD por transacción.
                </p>
              </Col>
            </SpanDark>
          </Row> */}
            <Row className="d-flex justify-content-center  align-items-end">
              <BodyData>
                <FormulariosGeneral>
                  <Row className="HeaderOPC1">
                    <div className="HeaderOPC1_row1"></div>
                    <div className="HeaderOPC1_row2">
                      Solicitamos que veas este corto video.
                    </div>
                  </Row>
                  <Row className="d-flex align-items-center justify-content-center subsection-video-portafolio-dividendo">
                    <div className="embed-responsive embed-responsive-16by9 portafolio-video wrapper">
                      <video
                        className=""
                        src={dividendos_video}
                        controls
                        poster={coverVideo}
                        controlsList=" nodownload noremoteplayback"
                        disablePictureInPicture
                        autoPlay={false}
                        loop
                      />
                    </div>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-left">
                      <Button
                        id="btnCloseCon"
                        name="btnCloseCon"
                        className="form-buttonPortafolio font-weight-bolder text-dark ml-0"
                        key="btnCloseCon"
                        onClick={handleBackClick}
                        variant="outline-success"
                        size="md"
                      >
                        Atrás
                      </Button>

                      <Button
                        id="btnBack"
                        className="form-buttonPortafolioDark font-weight-bolder ml-0"
                        name="btnBack"
                        key="btnBack"
                        onClick={handleNextClick}
                        size="md"
                      >
                        Siguiente
                      </Button>
                    </Col>
                  </Row>
                </FormulariosGeneral>
              </BodyData>
            </Row>
          </BodyFormulario>
        )}
        {page === 5 && (
          <BodyFormulario>
            {/* 
          <Row
            className="d-flex justify-content-center  align-items-end"
            Style=" padding: 20px;"
          >
            
            <SpanDark>
              <Col>
                <p className="pTitulo">ACH dentro de Estados Unidos</p>
                <p className="pDescripcion">
                  Esta opción tiene un costo de $5 USD por transacción.
                </p>
              </Col>
            </SpanDark>
          </Row> */}
            <Row className="d-flex justify-content-center  align-items-end">
              <BodyData>
                <FormulariosGeneral>
                  {loading ? (
                    <Col
                      sm={12}
                      className="d-flex align-items-center justify-content-center"
                    >
                      <Loader variant="dark"></Loader>{" "}
                    </Col>
                  ) : (
                    <Dividendos_socio
                      datos={datos}
                      handleInputChange={handleInputChange}
                      handleBackClick={handleBackClick}
                      handleNextClick={handleNextClick}
                      updateDatos={updateDatos}
                      inversiones={inversiones}
                    ></Dividendos_socio>
                  )}
                </FormulariosGeneral>
              </BodyData>
            </Row>
          </BodyFormulario>
        )}
      </ValidationForm>
    </>
  );
}

export default FormularioPagoPrincipalV2;
