import React from 'react';

const Login = React.lazy(() => import('./Views/login/login'));
const ResetPass = React.lazy(() => import('./Views/resetpass/resetpass'));
const UpdatePass = React.lazy(() => import('./Views/updatepass/updatepass'));

const route = [
    
    { path: '/auth/login', exact: true, name: 'Login', component: Login },
    { path: '/auth/reset', exact: true, name: 'ResetPass', component: ResetPass },
    { path: '/auth/updatepass/:id', exact: true, name: 'UpdatePass', component: UpdatePass }
];

export default route;