import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import "../FormularioPago.css";
import userContext from "../../../../context/userContext";

import { Card, Form, Col, Row, Button } from "react-bootstrap";

import MensajeAlertaSwal from "../../MensajeAlerta/MensajeAlertaSwal";
import {
  ValidationForm,
  TextInput,
  SelectGroup,
  Checkbox,
  FileInput
} from "react-bootstrap4-form-validation";
import unitedstates from "../../../../assets/images/portafolio/icons/united-states-logo.png";
import globo from "../../../../assets/images/portafolio/icons/internet-logo.png";
import payoneer from "../../../../assets/images/portafolio/icons/Payoneer-logo.png";
import "./Pago_transferencia.css";
import countries_list from "../../../../utils/countries_states.json";

export default function Pago_transferencia(props) {
  const [datos, setDatos] = useState({
    nombrelegal_t: props.datos.nombrelegal_t,
    email_t: props.datos.email_t,
    numerocuenta_t: props.datos.numerocuenta_t,
    nombreBanco_t: props.datos.nombreBanco_t,
    tipoCuenta_t: props.datos.tipoCuenta_t,
    tipoBeneficiario_t: props.datos.tipoBeneficiario_t,
    pais_t: props.datos.pais_t,
    telefono_t: props.datos.telefono_t,
    direccionlegal_t: props.datos.direccionlegal_t,
    direccioncalle_t: props.datos.direccioncalle_t,
    ciudad_t: props.datos.ciudad_t,
    estado_t: props.datos.estado_t,
    codigopostal_t: props.datos.codigopostal_t,
    confirmacion_t: props.datos.confirmacion_t,
    codigo_swift_t: props.datos.codigo_swift_t,
    iban_t: props.datos.iban_t
  });
  const [comboPaises, setcomboPaises] = useState();
  const [comboEstados, setcomboestados] = useState([]);
  const [estadoSel, setestadoSel] = useState(props.datos.estado_t);
  const [paisSel, setpaisSel] = useState(props.datos.pais_t);
  const [file, setfile] = useState();
  const [archivoName, setarchivoName] = useState(
    props.datos.file_form_name
      ? props.datos.file_form_name
      : props.datos.archivo
      ? "Ya hay un archivo en nuestros registros."
      : "Seleccionar archivo"
  );

  const onFileHandler = (event) => {
    if (event.target.files.length > 0) {
      setfile(event.target.files[0]);
      setarchivoName(event.target.files[0].name);
      handleInputChange({
        target: {
          name: "file_form_name",
          value: event.target.files[0].name
        }
      });
    } else {
      setfile();
      setarchivoName("Seleccionar Archivo");
    }
  };

  useEffect(() => {
    let countries = [];
    countries = countries_list.countries.map((item) => {
      return {
        key: item.country,
        value: item.country
      };
    });
    setcomboPaises(countries);

    if (datos.pais_t) {
      let estados = countries_list.countries.filter((item) => {
        return item.country === datos.pais_t;
      });
      if (estados.length > 0) {
        if (estados[0].states.length > 0) {
          let cestados = estados[0].states.map((item) => {
            return {
              key: item,
              value: item
            };
          });
          setcomboestados(cestados);
        }
      }
    }

    return () => {};
  }, []);

  function updateEstados(e) {
    setestadoSel(null);
    let estados = countries_list.countries.filter((item) => {
      return item.country === e.target.value;
    });
    if (estados.length > 0) {
      if (estados[0].states.length > 0) {
        let cestados = estados[0].states.map((item) => {
          return {
            key: item,
            value: item
          };
        });
        setcomboestados(cestados);
      }
    }
  }
  const handleInputChange = (
    event,
    campoEspecial = false,
    tipoCampo = null,
    campoName = null,
    deptoSufijo = null,
    deptoprefijo = ""
  ) => {
    if (campoEspecial === false) {
      setDatos({
        ...datos,
        [event.target.name]: event.target.value
      });
    } else {
      switch (tipoCampo) {
        case "depto":
          let campo1 = `${deptoprefijo}depto${deptoSufijo}`;
          let campo2 = `${deptoprefijo}muni${deptoSufijo}`;
          setDatos({
            ...datos,
            [campo1]: event.target.value,
            [campo2]: ""
          });
          break;

        case "multi":
          setDatos({
            ...datos,
            [campoName]: event
          });
          break;
        case "check":
          setDatos({
            ...datos,
            [campoName]: event.target.checked
          });
          break;
        default:
        // code block
      }
    }
  };
  return (
    <Card>
      <Card.Body>
        <Row className="HeaderOPC1">
          <div className="HeaderOPC1_row1">
            <img src={globo} className="card-img-opc" alt="ACH"></img>
          </div>
          <div className="HeaderOPC1_row2">Transferencia Internacional</div>
          <div className="HeaderOPC1_row3">Incluye a Guatemala</div>
        </Row>
        <Row>
          <Col xs="12">
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Nombre exacto legal de la persona o empresa*
              </Form.Label>

              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="nombrelegal_t"
                id="nombrelegal_t"
                placeholder="Ingresa el nombre legal de persona o empresa"
                required
                type="text"
                autoComplete="off"
                value={datos.nombrelegal_t ? datos.nombrelegal_t : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Correo electrónico
              </Form.Label>

              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="email_t"
                id="email_t"
                placeholder="Ingresa Correo electrónico"
                required
                type="email"
                autoComplete="off"
                value={datos.email_t ? datos.email_t : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Nombre del Banco
              </Form.Label>

              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="nombreBanco_t"
                id="nombreBanco_t"
                placeholder="Ingresa Nombre de banco"
                required
                type="text"
                autoComplete="off"
                value={datos.nombreBanco_t ? datos.nombreBanco_t : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Tipo de Beneficiario
              </Form.Label>

              <SelectGroup
                className="form-control form-controlPortafolioFormulario"
                name="tipoBeneficiario_t"
                id="tipoBeneficiario_t"
                defaultValue={datos.tipoBeneficiario_t}
                value={datos.tipoBeneficiario_t}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                required
              >
                <option key="" value="">
                  ---Seleccione una opcion---
                </option>{" "}
                <option key="Persona" value="Persona">
                  Persona
                </option>
                <option key="Empresa" value="Empresa">
                  Empresa
                </option>
              </SelectGroup>
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="font-size-subtitle">
                Datos del beneficiario
              </Form.Label>
              <hr></hr>
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                País o territorio
              </Form.Label>

              <SelectGroup
                className="form-control form-controlPortafolioFormulario"
                name="pais_t"
                id="pais_t"
                value={paisSel}
                required
                onChange={(e) => {
                  /* handleInputChange(e); */
                  setpaisSel(e.target.value);
                  updateEstados(e);
                }}
              >
                <option key="" value="">
                  ---Seleccione una opcion---
                </option>{" "}
                {!(comboPaises === undefined)
                  ? comboPaises.map((fbb) => (
                      <option key={fbb.key} value={fbb.value}>
                        {fbb.value}
                      </option>
                    ))
                  : null}
              </SelectGroup>
            </Form.Group>

            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Número de teléfono (con código de país)
              </Form.Label>

              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="telefono_t"
                id="telefono_t"
                placeholder="Número de teléfono (con código de país)"
                required
                type="text"
                autoComplete="off"
                value={datos.telefono_t ? datos.telefono_t : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Dirección Legal
              </Form.Label>
              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="direccionlegal_t"
                id="direccionlegal_t"
                placeholder="Dirección Legal"
                required
                type="text"
                autoComplete="off"
                value={datos.direccionlegal_t ? datos.direccionlegal_t : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Calle/ Apartamento/ Piso (opcional)
              </Form.Label>
              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="direccioncalle_t"
                id="direccioncalle_t"
                placeholder="Calle/ Apartamento/ Piso (opcional)
                "
                required
                type="text"
                autoComplete="off"
                value={datos.direccioncalle_t ? datos.direccioncalle_t : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Ciudad
              </Form.Label>
              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="ciudad_t"
                id="ciudad_t"
                placeholder="Ciudad"
                required
                type="text"
                autoComplete="off"
                value={datos.ciudad_t ? datos.ciudad_t : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Estado / Provincia / Región
              </Form.Label>

              <SelectGroup
                className="form-control form-controlPortafolioFormulario"
                name="estado_t"
                id="estado_t"
                value={estadoSel}
                defaultValue={estadoSel}
                onChange={(e) => {
                  setestadoSel(e.target.value);
                }}
                required
              >
                <option key="" value="">
                  ---Seleccione una opcion---
                </option>{" "}
                {!(comboEstados === undefined)
                  ? comboEstados.map((fbb) => (
                      <option key={fbb.key} value={fbb.value}>
                        {fbb.value}
                      </option>
                    ))
                  : null}
              </SelectGroup>
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Código postal
              </Form.Label>
              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="codigopostal_t"
                id="codigopostal_t"
                placeholder="Código postal"
                required
                type="text"
                autoComplete="off"
                value={datos.codigopostal_t ? datos.codigopostal_t : ""}
                onChange={handleInputChange}
              />
            </Form.Group>

            <Form.Group className="">
              <Form.Label className="font-size-subtitle">
                Datos bancarios
              </Form.Label>
              <hr></hr>
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Código SWIFT
              </Form.Label>
              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="codigo_swift_t"
                id="codigo_swift_t"
                placeholder="Código SWIFT"
                required
                type="text"
                autoComplete="off"
                value={datos.codigo_swift_t ? datos.codigo_swift_t : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">IBAN</Form.Label>
              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="iban_t"
                id="iban_t"
                placeholder="IBAN"
                required
                type="text"
                autoComplete="off"
                value={datos.iban_t ? datos.iban_t : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Número de cuenta
              </Form.Label>

              <TextInput
                className="form-control form-controlPortafolioFormulario font-weight-bolder"
                name="numerocuenta_t"
                id="numerocuenta_t"
                placeholder="Ingresa Número de cuenta"
                required
                type="text"
                autoComplete="off"
                value={datos.numerocuenta_t ? datos.numerocuenta_t : ""}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Tipo de Cuenta
              </Form.Label>

              <SelectGroup
                className="form-control form-controlPortafolioFormulario"
                name="tipoCuenta_t"
                id="tipoCuenta_t"
                defaultValue={datos.tipoCuenta_t}
                value={datos.tipoCuenta_t}
                onChange={(e) => {
                  handleInputChange(e);
                }}
                required
              >
                <option key="" value="">
                  ---Seleccione una opcion---
                </option>{" "}
                <option key="Business Checking" value="Business Checking">
                  Business Checking
                </option>
                <option key="Business Savings" value="Business Checking">
                  Business Savings
                </option>
                <option key="Personal Checking" value="Personal Checking">
                  Personal Checking
                </option>
                <option key="Personal Savings" value="Personal Savings">
                  Personal Savings
                </option>
              </SelectGroup>
            </Form.Group>
            <Form.Group className="">
              <Form.Label className="pb-2  font-weight-bolder">
                Documento (foto de cheque o documento formal) de tu banco en
                donde se lea tu número de cuenta y nombre de banco de manera
                legible
              </Form.Label>
            </Form.Group>
            <Form.Group className="">
              <Form.Label
                className="form-control form-controlPortafolioFormulario form-controlPortafolioInputCustom"
                htmlFor="fileForm"
              >
                <span className="feather icon-upload-cloud icon-feather-portafolio"></span>
                <span>{archivoName}</span>
              </Form.Label>
              <FileInput
                required={props.datos.archivo ? false : true}
                aria-describedby="custom-addons5"
                type="file"
                accept="application/pdf,image/jpg,image/jpeg,image/png,application/msword"
                className="inputfile-custom "
                id="fileForm"
                onChange={onFileHandler}
              />
            </Form.Group>
            <Form.Group className="form-group-checkbox-pd">
              <Checkbox
                name="confirmacion_t"
                label="Confirmo que mi información bancaria está correcta. Esto es solo una actualización de datos."
                id="confirmacion_t"
                required
                errorMessage="Es requerido confirmar la información es correcta."
                value={datos.confirmacion_t}
                onChange={(e) => {
                  handleInputChange(e, true, "check", "confirmacion_t");
                }}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-left">
            <Button
              id="btnCloseCon"
              name="btnCloseCon"
              className="form-buttonPortafolio font-weight-bolder text-dark ml-0"
              key="btnCloseCon"
              onClick={props.handleBackClick}
              variant="outline-success"
              size="md"
            >
              Atrás
            </Button>

            <Button
              id="btnBack"
              className="form-buttonPortafolioDark font-weight-bolder ml-0"
              name="btnBack"
              key="btnBack"
              type="submit"
              onClick={(e) => {
                let tags = {
                  estado_t: estadoSel,
                  pais_t: paisSel
                };
                let result = {
                  ...datos,
                  ...tags
                };
                if (!(file === undefined || file === null)) {
                  const data = new FormData();
                  var re = /(?:\.([^.]+))?$/;
                  data.append("File", file, file.name);

                  let filej = {
                    file_data: data
                  };
                  result = {
                    ...result,
                    ...filej
                  };
                }
                props.updateDatos(result);
              }}
              size="md"
            >
              Siguiente
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}
