import { createContext } from 'react';

const userContext = createContext({
  user:[]
}); // Create a context object




 export default userContext

